.container {
  display: flex;
  justify-content: space-between;

  padding: 1rem;

  width: 50vw;
  height: 50vh;

  border: 1px solid #4785e0;
  border-radius: 5px;
  box-shadow: 0 0 10px #4785e0;
}

.icon_container {
  width: 3rem;
  height: 3rem;
  align-self: flex-end;
}

.empty_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 37vh;
}

.text {
  font-size: large;
  font-weight: 500;
}